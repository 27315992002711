// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAr9IIM8n-TNY5FILQlN9-1RxQ8WCQrsvo",
  authDomain: "upload-38414.firebaseapp.com",
  projectId: "upload-38414",
  storageBucket: "upload-38414.appspot.com",
  messagingSenderId: "141120377192",
  appId: "1:141120377192:web:f3e113c31cefd998fa06dd",
  measurementId: "G-32J89B40JZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };

