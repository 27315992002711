import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

function Title(props) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  return (
    <div>
      { isDesktopOrLaptop ?
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.children}
      </Typography>
      :
      <Typography component="div" variant="subtitle1" color="primary" gutterBottom>
        {props.children}
      </Typography>
      }
    </div>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
